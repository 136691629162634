import { FormControl, FormErrorMessage, FormLabel, GridItem, SimpleGrid, Textarea, VisuallyHidden } from "@chakra-ui/react";
import { DeepRequired, FieldErrorsImpl, UseFormRegister } from "react-hook-form";
import { ContactFormProps } from "./ContactForm";
import ContactFormLabel from "./ContactFormLabel";

interface ContactTextAreaFieldProps {
  showLabels: boolean;
  colorScheme: 'light' | 'dark';
  fieldName: "location" | "name" | "type" | "size" | "comments" | "phone" | "email" | "organization" | `location.${number}`;
  fieldLabel: string;
  isRequired: boolean;
  fieldSize: 'sm' | 'md' | 'lg' | 'xl';
  register: UseFormRegister<ContactFormProps>;
  errors: FieldErrorsImpl<DeepRequired<ContactFormProps>>;
  submitting: boolean;
}

const ContactTextAreaField = (props: ContactTextAreaFieldProps):JSX.Element => (
  <FormControl
    borderRadius={'lg'}
    mb="4"
    isInvalid={!!props.errors[props.fieldName]}
    isDisabled={props.submitting}
  >
    <SimpleGrid columns={{ base: 1, md: props.showLabels ? 4 : 3 }}>
      {
        props.showLabels &&
        <GridItem colSpan={1}>
          <ContactFormLabel
            forField={props.fieldName}
            label={props.fieldLabel}
            color={props.colorScheme === 'light' ? 'brand.darkgray.600' : 'brand.green.600'}
          />
        </GridItem>
      }
      <GridItem colSpan={3}>
        {
          !props.showLabels &&
          <VisuallyHidden>
            <FormLabel htmlFor={props.fieldName} color="white">{props.fieldLabel}</FormLabel>
          </VisuallyHidden>
        }
        <Textarea
          as={Textarea}
          name={props.fieldName}
          variant="outline"
          size={props.fieldSize}
          layerStyle={props.colorScheme === 'light' ? 'lightForm' : 'darkForm'}
          placeholder={props.fieldLabel}
          {...props.register(props.fieldName, { required: props.isRequired })}
        />
        <FormErrorMessage>{props.errors[props.fieldName]?.message}</FormErrorMessage>
      </GridItem>
    </SimpleGrid>
  </FormControl>
)

export default ContactTextAreaField;