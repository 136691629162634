import { Box, Button, Flex, GridItem, LinkBox, LinkOverlay, propNames, SimpleGrid, Tag, Text } from "@chakra-ui/react";
import { storyblokEditable } from "@storyblok/react";
import React, { useMemo, useState } from "react";
import { filterLocations } from "../../utils/locationHooks";
import { Card } from "../layout/Card";
import { Cards } from "../layout/Cards";
import Link from "next/link";
import ButtonLink from "../buttons/ButtonLink";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface Ppm365LocationCardsProps {
  blok: any;
  globalState: GlobalState;
  cardHeight?: string;
  children?: React.ReactNode
  cardBoxShadow?: boolean;
  showGreatestNeed?: boolean;
  showSponsor?: boolean
}

const Ppm365LocationCards = ({ blok, globalState, cardHeight, children, cardBoxShadow, showGreatestNeed = false, showSponsor = false }: Ppm365LocationCardsProps): JSX.Element => {

  if (cardBoxShadow === undefined) cardBoxShadow = true;

  const [locationFilter, setLocationFilter] = useState<string>('All Locations');

  const handleChangeLocationFilter = (filterValue: string) => {
    setLocationFilter(filterValue);
  }

  const locations = globalState.ppm365Locations

  const filteredLocations = filterLocations(locationFilter, locations);
  const emptyColumns = useMemo(() => {

    return  3 - ((filteredLocations.length - 2) % 3)


  }, [filteredLocations.length])

  

  const regions: string[] = locations.map(location => location.region).sort((a, b) => a.localeCompare(b));
  const regionArray = Array.from(new Set(regions));

  let buttonColumns = Math.min(regionArray.length + 2, 3);

  const GridButton = ({ filterString }) => {
    return (
      <GridItem>
        <Button
          size="sm"
          variant={filterString === locationFilter ? 'solid' : 'outline'}
          colorScheme="brand.green"
          onClick={() => handleChangeLocationFilter(filterString)}
          w="100%"
          textTransform={'uppercase'}
        >
          {filterString}
        </Button>
      </GridItem>
    )
  }

  return (
    <Box
      {...storyblokEditable(blok)}
      w="full"
    >
      {
        false && !blok.hideButtons &&
        <SimpleGrid
          columns={{ base: 1, md: 2, lg: buttonColumns }}
          alignItems="center"
          justifyContent="center"
          w="full"
          gap="4"
          mb="5"
        >
          <GridButton filterString="All Locations" />
          {regionArray.length > 1 && regionArray.map((region, index) => <GridButton key={index} filterString={region} />)}
        </SimpleGrid>
      }

      <Cards>
        {children}
        {
          filteredLocations.map((location, index) => {

            return (
              <Card key={index} imageSrc={`${location?.image.filename}/m/650x650/smart`} imageBlurDataUrl={`${location?.image.filename}/m/10x0`}
                tagLine={""}
                titleText={location?.region === 'USA' ? 'Domestic' : 'International'}
                subText={location?.name}
                locationLink={location}
                locationLinkType="ppm365"
                height={cardHeight}
                gridItem={{ boxShadow: "none" }}
              ></Card>

            )
          })
        }
        {
          showGreatestNeed && (emptyColumns === 3 || emptyColumns === 1) &&
          <GreatestNeed></GreatestNeed>
        }
      
        {
          showSponsor &&
          <SponsorSearch></SponsorSearch>
        }

        {
          showGreatestNeed && emptyColumns === 2 &&
          <GreatestNeed></GreatestNeed>
        }

      </Cards>
    </Box>
  )
};

const GreatestNeed = () => {
  return (
    <Flex direction={"column"} alignItems={"center"}>
    <Text style={{ textAlign: "center" }}>Not sure what location to choose?</Text>
    <Link href="/greatest-need" legacyBehavior>
      <Button colorScheme={"brand.green"} rightIcon={<FontAwesomeIcon icon={"circle-info"}></FontAwesomeIcon>}>Let us Help</Button>
    </Link>

  </Flex>
  );
}

const SponsorSearch = () =>  {
  return (
    <GridItem colSpan={2}>
              <Flex direction={"column"} alignItems={"center"}>
                <Text style={{ textAlign: "center" }} fontSize="xl">Want to transform a community by supporting a church leader?</Text>
                <Link href="/365/partners" legacyBehavior>
                  <Button colorScheme={"brand.orange"} rightIcon={<FontAwesomeIcon icon={"magnifying-glass"}></FontAwesomeIcon>}>Search for a Pastor to Sponsor</Button>
                </Link>

              </Flex>
            </GridItem>
  );
}

export default Ppm365LocationCards;
