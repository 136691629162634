import Link from "next/link"

export type LocationLinkType = "standard" | "ppm365" | "trip-search"

export interface LocationLinkProps {
  location: StoryblokLocation,
  linkType: LocationLinkType,
  children?: React.ReactNode,
  anchor?: string
}

export const LocationLink = ({ location, linkType = "standard", children, anchor }: LocationLinkProps): JSX.Element => {



  let linkHref: string = "";

  const locationUrlArray = location?.pageLink?.cachedUrl.split('/');
  const locationUrlSlug = locationUrlArray[locationUrlArray.length - 1];


  if (locationUrlArray.length > 2 && locationUrlArray[1] === 'es') {
    linkHref = `/${locationUrlArray[1]}`
  }


  if (linkType === "trip-search") {
    linkHref += `/mission-trip-dates?search=${location.rootsId}`
  }
  else {
    let path = "mission-trips"

    if (linkType === "ppm365") {
      path = "365"
    }

    linkHref += `/${path}/${locationUrlSlug}`

  }


  if (anchor) {
    linkHref += anchor.startsWith("#") ? anchor : "#" + anchor
  }

  return (
    <Link href={linkHref} passHref legacyBehavior>
      {children}
    </Link>
  );
}