import {
    Box,
    Container,
    Flex,
    Grid,
    GridItem,
    Link,
    Stack,
    Text,
    Button,
    useToast,
    IconButton} from '@chakra-ui/react';
  import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
  import NextLink from 'next/link';
  import { Dispatch, SetStateAction, useEffect, useState } from 'react';
  import SignInButton from './sign-in/SignInButton';
  import GoogleTranslate from '../../misc/googleTranslate';
  import { saveUserSelectedLanguage } from '../../../utils/googleTranslateUtils';
  
  interface Props {
    visible: boolean;
    globalState: GlobalState;
    setGlobalState: Dispatch<SetStateAction<GlobalState>>;
  }
  
  const SelectLanguageSubNav = ({ showSelectLanguageSubNav, globalState, setGlobalState, setShowSelectLanguageSubNav }) => {
    const toast = useToast();

    const closeLanguageSelectionClicked = () => {
        setShowSelectLanguageSubNav(false);
    }
  
    const saveLanguageButtonClicked = () => {
      setShowSelectLanguageSubNav(false)
      saveUserSelectedLanguage();
  
      toast({
        position: 'bottom',
        render: () => (
          <Flex 
            color='brand.green.600' 
            p={3} 
            bg='whiteAlpha.900'
            borderRadius={'lg'}
            borderColor='brand.green.600'
            borderWidth={'1px'}
            alignItems='center'
            justifyContent={'center'}
          >
            <FontAwesomeIcon
              icon={['fas', 'check']}
              color={`brand.green.600`}
              style={{ marginRight: '10px' }}
            />
            <Text>Language selection saved</Text>
          </Flex>
        ),
      })
    }
  
    return (
      <>
        <Container
          className={'select-language-sub-nav'}
          maxW={'370px'}
          position={'absolute'}
          zIndex={showSelectLanguageSubNav ? '2000' : '-2000'}
          top={{base: '605px', lg: '50px'}}
          right={{base: '0', lg: '0px'}}
          left={{base: '0', lg: globalState?.user ? '58%' : '65%'}}
          margin={{base: 'auto', lg: '0'}}
          bgColor="white"
          border="1px solid"
          boxShadow={'lg'}
          borderColor={'brand.orange.600'}
          rounded={'xl'}
          transition={'opacity 0.3s'}
          opacity={showSelectLanguageSubNav? '1' : '0'}
          display={'flex'}
          flexDirection={'column'}
          p={0}
        >
          <IconButton
            onClick={closeLanguageSelectionClicked}
            icon={<FontAwesomeIcon icon={['fas', 'xmark']} />}
            alignSelf={'end'}
            variant={'ghost'}
            aria-label={'Toggle Navigation'}
          />
          <Flex
            p={8}
            pt={0}
            flexDirection={'column'}
            justifyContent={'center'}
          >
            <Text
              justifyContent={'center'}
              textAlign={'center'}
            >
              We use Google Translate to automate translation from English to other languages.
              <br /><br />
              This translator may display innacurate information that does not represent PPM&apos;s views.
              <br /><br />
              Please proceed only if you consent.
            </Text>
            <Flex
              marginY={5}
              justifyContent={'center'}
            >
              <GoogleTranslate></GoogleTranslate>
            </Flex>
            <Button
              size="sm"
              colorScheme="brand.green"
              width={'75%'}
              alignSelf={'center'}
              hidden={globalState?.user ? false : true}
              onClick={saveLanguageButtonClicked}
            >
              SAVE SELECTION
            </Button>
          </Flex>
        </Container>
      </>
    );
  };
  
  export default SelectLanguageSubNav;
  