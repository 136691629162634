import { useDisclosure } from "@chakra-ui/react";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { Dispatch, SetStateAction, useState } from "react";
import ResetPasswordModal from "../layout/navigation/sign-in/ResetPasswordModal";
import SignInModal from "../layout/navigation/sign-in/SignInModal";
import SignOutModal from "../layout/navigation/sign-in/SignOutModal";
import AlertModal from "./AlertModal";

export interface SignInModalsProps {
    globalState: GlobalState,
    setGlobalState: Dispatch<SetStateAction<GlobalState>>,
    signInModal: Disclosure,
    signOutModal: Disclosure,
    resetPasswordModal: Disclosure,
    alertModal: Disclosure
}

interface Disclosure  {
    isOpen: boolean;
    onOpen: () => void;
    onClose: () => void;
    onToggle: () => void;
    isControlled: boolean;
    getButtonProps: (props?: any) => any;
    getDisclosureProps: (props?: any) => any;
}

const SignInModals = ({globalState, setGlobalState, signInModal, signOutModal,resetPasswordModal,alertModal}: SignInModalsProps): JSX.Element => {


  const [alertModalData, setAlertModalData] = useState<AlertModalProps>({
    icon: ['far', 'circle-check'] as IconProp,
    iconColor: 'brand.green.600',
    title: '',
    content: ``
  })

    return (
        <>
          <SignInModal 
        onClose={signInModal.onClose}
        isOpen={signInModal.isOpen}
        onOpenResetModal={resetPasswordModal.onOpen}
        globalState={globalState}
        setGlobalState={setGlobalState}
        setAlertModalData={setAlertModalData}
        openAlertModal={alertModal.onOpen}
        closeAlertModal={alertModal.onClose}
        locale={"en"}
      />
      <SignOutModal 
        onClose={signOutModal.onClose}
        isOpen={signOutModal.isOpen}
        globalState={globalState}
        setGlobalState={setGlobalState}
        setAlertModalData={setAlertModalData}
        openAlertModal={alertModal.onOpen}
      />
      <ResetPasswordModal
        onClose={resetPasswordModal.onClose}
        isOpen={resetPasswordModal.isOpen}
        setAlertModalData={setAlertModalData}
        openAlertModal={alertModal.onOpen}
      />
      <AlertModal
        onClose={alertModal.onClose}
        isOpen={alertModal.isOpen}
        iconColor={alertModalData.iconColor}
        icon={alertModalData.icon}
        title={alertModalData.title}
        content={alertModalData.content}
        buttons={alertModalData.buttons}
        dontBlockScrollOnMount={true}
      />
      </>
    )
}

export default SignInModals