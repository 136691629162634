import { Button, Spinner, useDisclosure } from "@chakra-ui/react";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { getCookie } from "../../../../utils/misc";
import AlertModal from "../../../misc/AlertModal";
import SignInModals from "../../../misc/SignInModals";
import ResetPasswordModal from "./ResetPasswordModal";
import SignInModal from "./SignInModal";
import SignOutModal from "./SignOutModal";
import { checkSignIn, getPaToken, isPaTokenExpired } from "../../../../utils/pelicanAccount"
import { useRouter } from "next/router";
import { setUserSelectedLanguage } from "../../../../utils/googleTranslateUtils";

interface Props {
  type: 'mobile' | 'desktop';
  globalState: GlobalState;
  setGlobalState: Dispatch<SetStateAction<GlobalState>>;
}

const SignInButton = ({ type, globalState, setGlobalState }: Props): JSX.Element => {
  const signInModal = useDisclosure();
  const signOutModal = useDisclosure();
  const resetPasswordModal = useDisclosure();
  const alertModal = useDisclosure();
  const [signingIn, setSigningIn] = useState<boolean>(true);
  const router = useRouter()




  useEffect(() => {
    
    if (router.isReady) {

      const { loginPrompt } = router.query

      const token = getPaToken()

      if (loginPrompt && (!token || isPaTokenExpired(token) || token.ID !== Number(loginPrompt))) {

        signInModal.onOpen()
      
        
        setSigningIn(false)
      }
      else if (token && !globalState.user) {
        try {
          checkSignIn().then((data) => {
            if (data.userName) {
              setGlobalState({
                ...globalState,
                user: {
                  name: data.userName,
                  email: data.email,
                  id: data.id,
                  firstName: data.firstname,
                  lastName: data.lastname,
                  address1: data.address1,
                  address2: data.address2,
                  city: data.city,
                  state: data.state,
                  zipcode: data.zipcode.toString(),
                  selectedLanguage: data.selectedLanguage
                }
              })

              if (data.selectedLanguage) {
                setTimeout(() => {
                  setUserSelectedLanguage(data.selectedLanguage);
                }, 1000);
              }
            }
            setSigningIn(false);
          })
        } catch (e) {
          console.error(e);
        }
      } else {
        setSigningIn(false);
      }

      //Remove from query string to clean up URL for sharing, etc.
      const { pathname, query } = router
      delete router.query.loginPrompt
      router.replace({ pathname, query }, undefined, { shallow: true })
      
    }
    //we only want to execute this once after the router is ready
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.isReady]);

  return (
    <>
      <SignInModals
        globalState={globalState}
        setGlobalState={setGlobalState}
        alertModal={alertModal}
        resetPasswordModal={resetPasswordModal}
        signInModal={signInModal}
        signOutModal={signOutModal}
      ></SignInModals>
      {
        type === 'desktop' ?
          globalState.user?.email ?
            // signed in, desktop view (topbar)
            <Button
              variant="link"
              color={'brand.green.600'}
              fontWeight={'400'}
              display="flex"
              alignItems={'center'}
              gap="1"
              fontSize="sm"
              lineHeight={1}
              onClick={signOutModal.onOpen}
            >
              <FontAwesomeIcon icon={['fas', 'user']} />
              Signed in as {globalState.user?.name}
            </Button>
            :
            // not signed in, desktop view (topbar)
            <Button
              variant="link"
              color={'brand.green.600'}
              fontWeight={'400'}
              display="flex"
              alignItems={'center'}
              gap="1"
              fontSize="sm"
              lineHeight={1}
              onClick={signInModal.onOpen}
              disabled={signingIn}
            >
              <FontAwesomeIcon icon={['fas', 'user']} />
              {signingIn ? 'Loading...' : 'Sign In'}
            </Button>
          :
          globalState.user?.email ?
            // signed in, mobile nav view
            <Button
              w="full"
              variant="link"
              colorScheme="brand.orange"
              leftIcon={<FontAwesomeIcon icon={['fas', 'user']} />}
              onClick={signOutModal.onOpen}
            >
              Signed in as {globalState.user?.name}
            </Button>
            :
            // not signed in, mobile nav view
            <Button
              w="full"
              variant="link"
              colorScheme="brand.orange"
              leftIcon={<FontAwesomeIcon icon={['fas', 'user']} />}
              onClick={signInModal.onOpen}
              disabled={signingIn}
            >
              {signingIn ? 'Loading...' : 'Sign In'}
            </Button>
      }
    </>
  )
}

export default SignInButton;