import { getCookie, triggerEvent } from "./misc";
import { putUserSelectedLanguage } from "./ppmApi";

export const triggerGoogleTranslateElement = (selectedLanguage) => {
    var googleTranslateDropdown = (document.querySelector('.goog-te-combo') as HTMLInputElement);
    
    if (googleTranslateDropdown) {
      googleTranslateDropdown.value = selectedLanguage;
      triggerEvent(googleTranslateDropdown, 'change');
    }
  }
  
  export const setUserSelectedLanguage = (selectedLanguage: string) => {
    const googleTranslateCookie = decodeURI(getCookie('googtrans'));
    if (!googleTranslateCookie) {
      triggerGoogleTranslateElement(selectedLanguage);
    }
  }
  
  export const saveUserSelectedLanguage = () => {
    let selectedLanguage = '';
    const googleTranslateCookie = decodeURI(getCookie('googtrans'));

    if (googleTranslateCookie) {
      // slice off the first 4 characters because we only need the language value, cookie value is '/en/{language value}'
      selectedLanguage = googleTranslateCookie.slice(4);
    }

    putUserSelectedLanguage(selectedLanguage);
  }