import { Flex, FormControl, VisuallyHidden, FormLabel, Input, FormErrorMessage, Box, Text, Button } from "@chakra-ui/react"
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { setUserSelectedLanguage } from "../../../../utils/googleTranslateUtils";
import { checkSignIn, SignInFormType } from "../../../../utils/pelicanAccount";
import { DynamicComponentProps, DynamicComponentPropsWithChildren } from "../../../DynamicComponent";

interface SignInFormProps extends DynamicComponentProps<any> {
    onError?: (error: any) => void,
    signedIn?: () => void,
    buttonText?: string
}

const SignInForm = ({ onError = () => { }, signedIn = () => { }, globalState, setGlobalState, locale, buttonText = "Sign In" }: SignInFormProps): JSX.Element => {

    const [errorMessage, setErrorMessage] = useState('')

    const { register, handleSubmit, formState, reset } = useForm<SignInFormType>({
        defaultValues: {
            email: '',
            password: ''
        }
    });

    const handleSignIn = async (values) => {
        try {
            setErrorMessage('');

            const sendForm = await fetch(`${process.env.NEXT_PUBLIC_PPMAPI_URL}/authenticate`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                credentials: 'include',
                body: JSON.stringify(values)
            })

            if (sendForm.ok) {
                const result = await sendForm.json();
                const data = await checkSignIn()

                setGlobalState({
                    ...globalState,
                    user: {
                        email: result.EMAIL,
                        name: result.USERNAME,
                        id: result.ID,
                        firstName: data.firstname,
                        lastName: data.lastname,
                        address1: data.address1,
                        address2: data.address2,
                        city: data.city,
                        state: data.state,
                        zipcode: data.zipcode.toString(),
                        selectedLanguage: data.selectedLanguage
                    }
                })

                if (data.selectedLanguage) {
                    setTimeout(() => {
                      setUserSelectedLanguage(data.selectedLanguage);
                    }, 1000);
                  }

                signedIn()

                reset();

            } else if (sendForm.status === 401) {
                setErrorMessage('Email or password is incorrect.');

            } else {
                console.error(sendForm);
                throw new Error('Failed to sign in')
            }

        } catch (error) {
            setErrorMessage("Looks like something went wrong.  Wait a few minutes and try again.")
            reset()
            console.error(error);
            onError(error)
        }
    }

    return (
        <>
            <form onSubmit={handleSubmit(handleSignIn)}>
                <Flex
                    direction="column"
                    gap="5"
                >
                    <FormControl
                        borderRadius={'lg'}
                        isInvalid={!!formState.errors['email']}
                        isDisabled={formState.isSubmitting}
                    >
                        <VisuallyHidden>
                            <FormLabel htmlFor={'email'}>
                                Email
                            </FormLabel>
                        </VisuallyHidden>

                        <Input
                            name={'email'}
                            type={'email'}
                            autoComplete={'username'}
                            variant="outline"
                            size={'md'}
                            layerStyle={'lightForm'}
                            placeholder={'Email Address'}
                            {...register('email', { required: 'Email is required' })}
                            border="1px solid"
                            borderColor={'brand.orange.600'}
                        />

                        <FormErrorMessage>
                            <Box textAlign="center" w="full">
                                {formState.errors['email']?.message}
                            </Box>
                        </FormErrorMessage>
                    </FormControl>

                    <FormControl
                        borderRadius={'lg'}
                        isInvalid={!!formState.errors['password']}
                        isDisabled={formState.isSubmitting}
                    >
                        <VisuallyHidden>
                            <FormLabel htmlFor={'password'}>
                                Password
                            </FormLabel>
                        </VisuallyHidden>

                        <Input
                            name={'password'}
                            type={'password'}
                            autoComplete={'current-password'}
                            variant="outline"
                            size={'md'}
                            layerStyle={'lightForm'}
                            placeholder={'Password'}
                            {...register('password', { required: 'Password is required' })}
                            border="1px solid"
                            borderColor={'brand.orange.600'}
                        />

                        <FormErrorMessage>
                            <Box textAlign="center" w="full">
                                {formState.errors['password']?.message}
                            </Box>
                        </FormErrorMessage>
                    </FormControl>

                    {
                        errorMessage &&
                        <Text mb="0" lineHeight={1} textAlign='center' color="red.500">{errorMessage}</Text>
                    }

                    <Button
                        type="submit"
                        colorScheme="brand.green"
                        disabled={formState.isSubmitting}
                    >
                        {buttonText}
                    </Button>
                </Flex>

            </form>



        </>
    )
}

export default SignInForm