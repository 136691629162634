
import SubNavGrid, { SubNavProps } from "../SubNavGrid";
import { Box, Button, Divider, Flex, GridItem, Link, Image, ResponsiveValue, LinkBox, LinkOverlay, List, ListItem, SimpleGrid, Stack, Text, Heading } from "@chakra-ui/react";
import NextLink from 'next/link';
import { Card } from "../../Card";
import { Cards } from "../../Cards";
import { SubNavPrimaryLink } from "../SubNavPrimaryLink";
import disasterResponseImage from "../../../../public/images/current-disaster-background.jpg"
import readinessTeam from "../../../../public/images/readiness-team.jpg"
import { SubNavSideBox } from "../SubNavSideBox";
import { Property } from "csstype";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { disasterResponseCopy } from "../../../../utils/copyVariables";


interface DisasterResponseLandingLinkProps {
  display?: ResponsiveValue<Property.Display>
  h?: string;
}


const DisasterResponseSubNav = ({ openNavItem, label, globalState }: SubNavProps): JSX.Element => {

  const featuredDisaster = globalState.disasters.find(d => d.featured === 1)


  return (
    <SubNavGrid
      visible={openNavItem === label}
      openNavItem={openNavItem}
      gap={5}
      templateColumns={'3fr 2fr'}
    >
      <Box maxH={{ base: 'auto', lg: `${60 * 9 + 8}px` }} overflowY={"auto"} overflowX={"auto"} pr={2}>
        <SubNavSideBox heading="">
          <Box>
            <DisasterResponseLandingLink />
            <Box mt='4' mb='2'>
              <Text textStyle={'subNavTitle'}>
                THE MISSION
              </Text>
              <Text textStyle={'subNavDescription'}>
                Praying Pelican Missions exists to build up, encourage, and assist the local Church, and that includes doing so in a church’s greatest time of need. When disasters strike, the Church is on the front lines of response and relief efforts, working with local authorities and organizing grassroots efforts to meet the pressing needs of their communities. Praying Pelican Missions is committed to coming alongside these efforts, providing the necessary resources, support, and help to advance the work of our local partners.
              </Text>
              <Text textStyle={'subNavDescription'}>
                {disasterResponseCopy}
              </Text>
            </Box>
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
            >
              <DisasterResponseButtonLink
                icon={["fas", "hand-holding-heart"]}
                title="Donate"
                subtitle={null}
                link={'/donate?modal=disasterModal'}
                target={"_blank"}
              />
            </Box>
          </Box>
        </SubNavSideBox>
      </Box>
      <Stack
        display={{ base: 'inherit', lg: 'block' }}
        maxH={{ base: 'auto', lg: `${60 * 9 + 8}px` }}
        overflowY={"auto"}
        pr={2}
        p="3"
        borderWidth={'1px'}
        borderColor={'brand.darkgray.600'}
        borderRadius="lg"
        bgColor="blackAlpha.50"
      >
        {featuredDisaster
          ? <SubNavPrimaryLink
            href={`/response/${featuredDisaster.url}`}
            title={featuredDisaster.title}
            text={featuredDisaster.theLocationName}
            image={`https://images.ppm.org/upload/disasterphotos/thumb/${featuredDisaster.url}.jpg?v=${featuredDisaster.photoVersion}`}
            imageAlt={featuredDisaster.title}
            h="200px"
          ></SubNavPrimaryLink>
          : <ReadinessTeamLandingLink />
        }

        <Text textStyle={'subNavTitle'} p='2' m="2">
          Current Responses
        </Text>
        <Box>
          <SimpleGrid columns={{ base: 1, md: 2 }} spacing={4}>
            {globalState.disasters.filter(d => d.featured === 0).map((disaster, index) => (
              <Card
                imageSrc={`https://images.ppm.org/upload/disasterphotos/thumb/${disaster.url}.jpg?v=${disaster.photoVersion}`}
                tagLine=""
                titleText={disaster.title}
                subText={disaster.theLocationName}
                key={index}
                gridItem={{ cursor: "pointer" }}
                disasterLink={disaster}
                locationLinkType="standard"
                height="250px"
              />
            ))}
          </SimpleGrid>
        </Box>
      </Stack>
    </SubNavGrid>
  );
}

const DisasterResponseLandingLink = ({ display }: DisasterResponseLandingLinkProps) => {



  return (
    <SubNavPrimaryLink
      href="/disaster-response"
      title="Disaster Response"
      text="When disasters strike, the Church is on the front lines of response and relief efforts."
      image={disasterResponseImage}
      imageAlt="Man and woman in front of a church"
      display={display}
      h="150px"
      objectPosition={'center bottom'}
    ></SubNavPrimaryLink>


  )
}

const ReadinessTeamLandingLink = ({ display }: DisasterResponseLandingLinkProps) => {

  return (
    <SubNavPrimaryLink
      href="/readiness-team"
      title="Readiness Team"
      text=""
      image={readinessTeam}
      imageAlt="insulation team with suits"
      display={display}
      h="200px"
    ></SubNavPrimaryLink>
  )
}


interface DisasterResponseButtonLinkProps {
  icon: IconProp;
  title: string;
  subtitle: string;
  link: string;
  target?: string;
}

const DisasterResponseButtonLink = (props: DisasterResponseButtonLinkProps): JSX.Element => {
  return (
    <LinkBox
      as={Link}
      href={props.link}
      bgColor="brand.orange.600"
      width="200px"
      height="45px"
      borderRadius="xl"
      display="flex"
      flexDirection="column"
      justifyContent={'center'}
      alignItems={'center'}
      _hover={{
        bgColor: "brand.orange.500",
        borderColor: "brand.orange.600",
        borderSize: '1px',
        textDecoration: 'none'
      }}
      transition={'background-color 0.1s ease-in-out'}
      cursor="pointer"
      p="3"
    >
      <Flex direction="row">
        <Text
          as="h4"
          color="white"
          textTransform={"uppercase"}
          fontWeight="bold"
        >
          <FontAwesomeIcon icon={props.icon} color="white" style={{ marginRight: 10 }} />
          {props.title}
        </Text>
      </Flex>
      {props.subtitle && (
        <Text
          textTransform={"uppercase"}
          color="white"
          textAlign={'center'}
          mb="0"
        >
          {props.subtitle}
        </Text>
      )}
    </LinkBox>
  )
}

export default DisasterResponseSubNav;