export function filterLocations(locationFilter: string, locations: StoryblokLocation[]) {
   
        const filteredLocations = locations.filter(location => {
            if (locationFilter === 'All Locations') {
                return true;
              } else if (locationFilter === 'Featured') {
                return location.rootsData.featured === 1;
              } else {
                return location.region === locationFilter;
              }
          })
            .sort((a, b) => {
              const aNameWithoutThe = a.name.indexOf('The ') === 0 ? a.name.replace('The ', '') : a.name;
              const bNameWithoutThe = b.name.indexOf('The ') === 0 ? b.name.replace('The ', '') : b.name;
    
              return aNameWithoutThe.localeCompare(bNameWithoutThe);
            })

    return filteredLocations
}