import {
  ApolloClient,
  InMemoryCache,
  ApolloLink,
  concat,
  HttpLink,
  gql
} from "@apollo/client";

// init graphql client
const httpLink = new HttpLink({ uri: "https://gapi.storyblok.com/v1/api" });

const authMiddleware = new ApolloLink((operation, forward) => {
  operation.setContext(({ headers = {} }) => ({
    headers: {
      ...headers,
      token: process.env.NEXT_PUBLIC_STORYBLOK_ACCESS_TOKEN,
      version: process.env.NEXT_PUBLIC_ENVIRONMENT === 'production' ? "published" : "draft",
      cv: new Date().getTime()
    },
  }));
  return forward(operation);
});

const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: concat(authMiddleware, httpLink),
});

const getGraphqlLanguageFilter = (locale, defaultLocale) => {
  let gqlLanguage = '';

  if (locale !== defaultLocale) {
    gqlLanguage = `, starts_with: "${locale}/*"`;
  }

  return gqlLanguage;
}

export const getMicrocopy = async (locale, defaultLocale) => {
  const gqlLanguage = getGraphqlLanguageFilter(locale, defaultLocale);

  const microcopyData = await client.query({
    query: gql`
      query Microcopy {
        MicrocopyItems(per_page: 100${gqlLanguage}) {
          items {
            slug
            content {
              content
            }
          }
        }
      }
    `,
  });

  const microcopy = {};

  for (let item of microcopyData.data.MicrocopyItems.items) {
    microcopy[item.slug] = item.content.content;
  }

  return microcopy;
}

export const getHeaderFooterLinks = async (locale, defaultLocale) => {
  const gqlLanguage = getGraphqlLanguageFilter(locale, defaultLocale);

  const headerFooterLinks = await client.query({
    query: gql`
      {
        FooterlinkItems(per_page: 100${gqlLanguage}) {
          items {
            content {
              component
              description
              linkText
              link {
                cachedUrl
                url
              }
            }
          }
        }
        FooterusefullinkItems(per_page: 100${gqlLanguage}) {
          items {
            content {
              component
              text
              link {
                cachedUrl
                url
              }
            }
          }
        }
      }

    `,
  });



  return {
    footerLinks: headerFooterLinks.data.FooterlinkItems.items.map(item => item.content),
    footerUsefulLinks: headerFooterLinks.data.FooterusefullinkItems.items.map(item => item.content),
  };
}

export const getLocations = async (locale, defaultLocale) => {
  const gqlLanguage = getGraphqlLanguageFilter(locale, defaultLocale);

  const locationData = await client.query({
    query: gql`
      {
        LocationItems(per_page: 100, ${gqlLanguage}) {
          items {
            content {
              rootsId
              name
              region
              image {
                alt
                filename
              }
              _uid
              pageLink {
                cachedUrl
              }
            }
          }
        }
      }
    `
  })

  const storyblokLocationData = locationData.data.LocationItems.items.map(item => item.content);

  const locationIdArray = storyblokLocationData.map(location => location.rootsId);

  const getRootsLocationData = await fetch(`${process.env.NEXT_PUBLIC_PPMAPI_URL}/home/roots/`, {
    method: 'POST',
    body: JSON.stringify({ locationArray: locationIdArray }),
    headers: { 'Content-Type': 'application/json' }
  });

  const rootsLocationData = await (getRootsLocationData).json();

  const returnData = [];

  for (let sbLocation of storyblokLocationData) {
    for (let rootsLocation of rootsLocationData) {
      if (sbLocation.rootsId === rootsLocation.id) {
        returnData.push({ ...sbLocation, rootsData: rootsLocation });
      }
    }
  }

  return returnData;
}

export const getPpm365Locations = async (locale, defaultLocale) => {
  const gqlLanguage = getGraphqlLanguageFilter(locale, defaultLocale);

  const locationData = await client.query({
    query: gql`
      {
        LocationItems(per_page: 100, ${gqlLanguage}) {
          items {
            content {
              rootsId
              name
              region
              image {
                alt
                filename
              }
              ppm365DonationFund
              _uid
              pageLink {
                cachedUrl
              }
            }
          }
        }
      }
    `
  })

  const storyblokLocationData = locationData.data.LocationItems.items.map(item => item.content);

  const locationIdArray = storyblokLocationData.map(location => location.rootsId);

  const getRootsLocationData = await fetch(`${process.env.NEXT_PUBLIC_PPMAPI_URL}/home/roots/365`, {
    method: 'POST',
    body: JSON.stringify({ locationArray: locationIdArray }),
    headers: { 'Content-Type': 'application/json' }
  });

  const rootsLocationData = await (getRootsLocationData).json();

  const returnData = [];

  for (let sbLocation of storyblokLocationData) {
    for (let rootsLocation of rootsLocationData) {
      if (sbLocation.rootsId === rootsLocation.id) {
        returnData.push({ ...sbLocation, rootsData: rootsLocation });
      }
    }
  }

  return returnData;
}

export const getJobOpenings = async (locale, defaultLocale) => {
  const gqlLanguage = getGraphqlLanguageFilter(locale, defaultLocale);

  const jobOpenings = await client.query({
    query: gql`
      {
        FeaturedjobopeningItems(per_page: 100, ${gqlLanguage}) {
          items {
            content {
              _uid
              commitment
              component
              department
              description
              image {
                alt
                filename
                focus
                name
                title
              }
              title
              location
              url {
                cachedUrl
              }
            }
          }
        }
        JobopeningItems(per_page: 100, ${gqlLanguage}) {
          items {
            content {
              url {
                cachedUrl
              }
              title
              location
              description
              department
              component
              commitment
              _uid
            }
          }
        }
      }
    `
  })

  return {
    featured: jobOpenings.data.FeaturedjobopeningItems.items.map(item => item.content),
    regular: jobOpenings.data.JobopeningItems.items.map(item => item.content),
  }
}

export default client;