import { Box, Button, Flex, GridItem, Link, LinkBox, LinkOverlay, ResponsiveValue, SimpleGrid, Stack, Text } from "@chakra-ui/react";
import NextLink from 'next/link';
import SubNavGrid, { SubNavProps } from "../SubNavGrid";
import Image from "next/legacy/image"
import Ppm365LocationCards from "../../../365/Ppm365LocationCards";
import ppm365Image from "../../../../public/images/ppm-365.jpg"
import { Property } from "csstype";
import { LocationLink } from "../../../locations/LocationLink";
import { SubNavPrimaryLink } from "../SubNavPrimaryLink";
import { SubNavSideBox } from "../SubNavSideBox";

interface Ppm365LandingLinkProps {
  display?: ResponsiveValue<Property.Display>
  h?: string;
}

const Ppm365SubNav = ({ openNavItem, label, globalState }: SubNavProps): JSX.Element => {

  const Ppm365LandingLink = ({ display }: Ppm365LandingLinkProps) => {



    return (
      <SubNavPrimaryLink
        href="/365"
        title="PPM365"
        text="A continuous effort to build up, encourage, and assist the local Church 365 days a year."
        image={ppm365Image}
        imageAlt="Man and woman in front of a church"
        display={display}
      ></SubNavPrimaryLink>


    )
  }

  return (

    <SubNavGrid
      visible={openNavItem === label}
      openNavItem={openNavItem}
      gap={5}
      templateColumns={'2fr 1fr'}
    >


      <Ppm365LandingLink display={{ base: 'inherit', lg: 'none' }} ></Ppm365LandingLink>


      <Box maxH={{base: 'auto', lg: `${60 * 9 + 8}px`}} overflowY={"auto"} overflowX={"auto"} display={{ base: 'none', lg: 'block' }} pr={2}>
        <Box >
          <Ppm365LocationCards
            blok={{ hideButtons: false, disableLocationLink: false }}
            globalState={globalState}
            cardHeight="110px"
            cardBoxShadow={false}
            showGreatestNeed={true}
            showSponsor={true}
            >
            <GridItem colSpan={2} rowSpan={2}>
              <Ppm365LandingLink></Ppm365LandingLink>
            </GridItem>
          </Ppm365LocationCards>
        </Box>
      </Box>




      <SubNavSideBox heading="Latest PPM365 Updates">
        <Flex height="100%" direction="column" justifyContent="space-evenly" alignSelf="stretch">
          {globalState.ppm365Updates.map((update, index) => {
            return (<Ppm365UpdateLink key={index} journal={update} globalState={globalState}></Ppm365UpdateLink>)
          })}
        </Flex>
      </SubNavSideBox>
      
    </SubNavGrid>
  )
}

export const Ppm365UpdateLink = ({ journal, textColor, globalState }: { journal: Ppm365Update, textColor?: string, globalState: GlobalState }) => {

  const location = globalState.ppm365Locations.find((l) => l.name === journal.locationName)

  return (
    <Flex key={journal.journalID} gap="3" alignItems={'center'}>
      <LinkBox>
        <LocationLink linkType="ppm365" location={location} anchor={`entry-${journal.journalID}`} >

          <LinkOverlay>
            <Box
              rounded="lg"
              overflow="hidden"
              position="relative"
              w="100px"
              h="60px"
              minW="100px"

            >
              {journal.photo ? 
              <Image
                src={`https://images.ppm.org/upload/365/${journal.journalID}.jpg`}
                alt={journal.entrytitle}
                layout="fill"
                objectFit={'cover'}
               
              />
              : <></>
}
            </Box>
          </LinkOverlay>
        </LocationLink>
      </LinkBox>
      <Flex justify="center" direction="column">
        <LocationLink linkType="ppm365" location={location} anchor={`entry-${journal.journalID}`} >
          <Link textStyle={'tripJournalNavLink'}>
            {journal.entrytitle}
          </Link>
        </LocationLink>
        <Text textStyle={'tripJournalNavInfo'} color={textColor ?? 'brand.darkgray.600'}>
          {journal.author}
        </Text>
        <Text textStyle={'tripJournalNavInfo'} color={textColor ?? 'brand.darkgray.600'}>
          {journal.locationName} |  {journal.journalDate}
        </Text>
      </Flex>
    </Flex>
  )
}

export default Ppm365SubNav;