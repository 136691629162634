import Script from 'next/script';
import React, { useEffect } from 'react';
declare global {
  interface Window {google: any, googleTranslateElementInit: any}
}

const GoogleTranslate = () => {
  const googleTranslateElementInit = () => {
    new window.google.translate.TranslateElement(
      {
        pageLanguage: 'en'
      },
      'google_translate_element'
    );
  };

  useEffect(() => {
    window.googleTranslateElementInit = googleTranslateElementInit;
  }, []);

  return (
  <>
    <Script strategy="lazyOnload" src={`//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit&abc=${(new Date()).valueOf()}`}></Script>
    <div id="google_translate_element"></div>
  </>
  );
};

export default GoogleTranslate;