import { FormLabel } from "@chakra-ui/react";

interface ContactFormLabel {
  forField: "location" | "name" | "type" | "size" | "comments" | "phone" | "email" | "organization" | `location.${number}`;
  label: string;
  color: string;
}

const ContactFormLabel = ({ label, forField, color }: ContactFormLabel) => (
  <FormLabel
    htmlFor={forField}
    color={color}
    textAlign={{ base: 'left', md: 'right' }}
    mb={0}
    mt={'6px'}
    fontWeight={'bold'}
    fontSize="sm"
  >
    {label}
  </FormLabel>
)

export default ContactFormLabel;