import { Grid } from "@chakra-ui/react";
import { Dispatch, ReactNode, SetStateAction, useContext } from "react";
import { NavigationContext } from "./Navigation";

export interface SubNavProps {
  openNavItem?: string;
  label?: string;
  globalState: GlobalState;
  setGlobalState: Dispatch<SetStateAction<GlobalState>>;
}

interface Props {
  children: ReactNode;
  columns?: number;
  templateColumns?: string;
  openNavItem: string;
  visible: boolean;
  gap?: number;
  minHeight?: string;
  display?: {};
}

const SubNavGrid = ({visible, children, openNavItem, columns, templateColumns, gap = 10, minHeight, display}:Props):JSX.Element => {
  const context = useContext(NavigationContext)
  
  const maxH = context.topBarVisible ? '70vh' : '75vh'

  return (

  <Grid
    templateColumns={{base: `1fr`, lg: columns ? `repeat(${columns}, 1fr)` : templateColumns}}
    gap={gap}
    transitionProperty={"opacity, max-height"}
    transitionDuration={openNavItem ? '0s' : '0.3s'}
    opacity={{ base: 1, lg: visible ? 1 : 0 }}
    maxHeight={{ base: 'fit-content', lg: visible ? maxH : 0 }}
    minHeight={minHeight}
    display={display}
    overflowY="auto"
    sx={{
      '&::-webkit-scrollbar': {
        width: '16px',
        borderRadius: 'lg',
        backgroundColor: `rgba(0, 0, 0, 0.2)`,
      },
      '&::-webkit-scrollbar-thumb': {
        borderRadius: 'lg',
        backgroundColor: `rgba(0, 0, 0, 0.2)`,
      },
    }}
  >
    {children}
  </Grid>
)}

export default SubNavGrid;