import { Flex, Text } from "@chakra-ui/react"

export const SubNavSideBox = ({ children, heading }) => {

    return (
        <>

            <Flex
                p="5"
                borderWidth={'1px'}
                borderColor={'brand.darkgray.600'}
                borderRadius="lg"
                bgColor="blackAlpha.50"
                height="100%"
                flexDirection="column"
                justifyContent="flex-start"
                gap={2}
              

            >
                {heading ? <Text
                    mb="0"
                    as="h4"
                    fontWeight={'bold'}
                    textAlign={'center'}
                    color="brand.green.600"
                    textTransform={'uppercase'}
                >
                    {heading}
                </Text> : ''}
               
                    {children}
               
            </Flex>
        </>

    )

}
