// TODO - refactor this so that it's a single global component
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  Center,
  Box,
  Text,
  ModalFooter,
  Button,
} from "@chakra-ui/react";
import { SizeProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface Props extends AlertModalProps {
  onClose: () => void;
  isOpen: boolean;
  // set dontBlockScrollOnMount to true if you're opening an alert modal on top of another modal
  dontBlockScrollOnMount?: boolean;
  buttons?: JSX.Element;
}

const AlertModal = (props: Props):JSX.Element => {
  return (
    <Modal onClose={props.onClose} isOpen={props.isOpen} isCentered blockScrollOnMount={!props.dontBlockScrollOnMount}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Center w="100%" flexDirection="column">
            <Box
              color={props.iconColor}
              my="4"
            >
              <FontAwesomeIcon
                icon={props.icon}
                size={'3x' as SizeProp}
              />
            </Box>
            <Text as="h2" fontSize="3xl" textAlign={'center'}>
              {props.title}
            </Text>
          </Center>
        </ModalHeader>
        <ModalBody>
          <Text fontSize="lg" color="brand.darkgray.600" textAlign="center">
            {props.content}
          </Text>
        </ModalBody>
        <ModalFooter justifyContent="center">
          {
            props.buttons ? 
            props.buttons : 
            <Button onClick={props.onClose} colorScheme="brand.green">
              OK
            </Button>
          }
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default AlertModal;