import { SimpleGrid } from "@chakra-ui/react"

export interface CardsProps {
    children?: React.ReactNode,
    cardCount?: number,
    style?: {}
}

export const Cards = ({ cardCount, children, style }: CardsProps) => {

    let columns = {
        base: 1,
        md: cardCount && cardCount < 2 ? cardCount : 2,
        lg: cardCount && cardCount < 3 ? cardCount : 3
    }



    return (
        <SimpleGrid
            columns={columns}
            gap="5"
            style={style}
        >
            {children}
        </SimpleGrid>
    )
}