import { useEffect } from "react";
import SubNavGrid, { SubNavProps } from "../SubNavGrid";

const SearchSubNav = ({ openNavItem, label, globalState }: SubNavProps):JSX.Element => {

  useEffect(() => {
    const cx = "8731967ac60114601";
    const gcse = document.createElement("script");
    gcse.type = "text/javascript";
    gcse.async = true;
    gcse.src = `https://cse.google.com/cse.js?cx=${cx}`;
    const s = document.getElementsByTagName("script")[0];
    s.parentNode.insertBefore(gcse, s);
  }, []);

  return (
    <SubNavGrid
      visible={openNavItem === label}
      openNavItem={openNavItem}
      minHeight="74px"
      display={{base: 'block', lg: openNavItem === label ? 'grid' : 'none'}}
    >
        <div className="gcse-search"/>
    </SubNavGrid>
  )
}

export default SearchSubNav;