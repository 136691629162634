import { Checkbox, FormControl, GridItem, SimpleGrid, Text } from "@chakra-ui/react";
import { UseFormRegister } from "react-hook-form";
import { IndividualApplicationForm } from "./IndividualContactModal";

interface LocationCheckboxesProps {
  heading: string;
  colorScheme: 'light' | 'dark';
  options: string[];
  name: 'location' | 'ministries' | 'timeframes';
  register: UseFormRegister<IndividualApplicationForm>;
  submitting: boolean;
}

const IndividualContactFormCheckboxes = (props:LocationCheckboxesProps):JSX.Element => {
  return (
    <>
      { 
        props.heading !== '' && 
        <Text
          color={props.colorScheme === 'light' ? 'brand.darkgray.600' : 'brand.green.600'}
          fontSize="sm"
          mb="0"
          textAlign={'center'}
        >
          {props.heading}
        </Text>
      }

      <SimpleGrid
        columns={{ base: 1, md: 2 }}
        maxH="36"
        overflowY="auto"
        border="1px solid"
        borderColor={props.colorScheme === 'light' ? 'blackAlpha.500' : 'whiteAlpha.700'}
        backgroundColor={props.colorScheme === 'light' ? 'white' : 'brand.darkgray.600'}
        borderRadius="lg"
        w="full"
        mb="4"
        sx={{
          '&::-webkit-scrollbar': {
            width: '16px',
            borderRadius: 'lg',
            backgroundColor: `rgba(0, 0, 0, 0.2)`,
          },
          '&::-webkit-scrollbar-thumb': {
            borderRadius: 'lg',
            backgroundColor: `rgba(0, 0, 0, 0.2)`,
          },
        }}
      >
        {
          props.options
            .map((option, index) =>
              <GridItem
                key={index}
              >
                <FormControl isDisabled={props.submitting}>
                  <Checkbox
                    m="2"
                    colorScheme={'brand.green'}
                    color={props.colorScheme === 'light' ? 'brand.darkgray.600' : 'white'}
                    value={option}
                    {...props.register(props.name, { required: false })}
                  >
                    {option}
                  </Checkbox>
                </FormControl>
              </GridItem>
            )
        }
      </SimpleGrid>
    </>
  )
}

export default IndividualContactFormCheckboxes;